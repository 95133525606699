<template>
  <v-data-table
    :headers="transactions_headers"
    :items="transactions"
    :items-per-page="10"
    :loading="loading"
    loading-text="Loading... Please wait"
    class="elevation-0"
  >
    <template v-slot:item.active_status="{ item }">
      <v-chip
        :color="
          item.status == 'Pending'
            ? 'warning'
            : item.status == 'Approved'
            ? 'green'
            : 'red'
        "
        dark
      >
        {{ item.status }}
      </v-chip>
    </template>
    <template v-slot:item.action="{ item }">
      <p class="mb-0 blue--text text-decoration-underline" style="cursor : pointer;" @click="onViewReceipt(item)">View Receipt</p>
    </template>
    <template v-slot:item.custom_date="{ item }">
      <p v-if="item.created_date">
        {{ new Date(item.created_date).toLocaleString() }}
      </p>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ["render"],
  data() {
    return {
      seller_id: '',
      loading: true,
      transactions_headers: [
        {
          text: "Reference No",
          align: "start",
          sortable: false,
          value: "reference_number",
          class: "custom-header black--text",
          divider: true,
        },
        {
          text: "Status",
          value: "active_status",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
           align: "center",
        },
        {
          text: "Created Date",
          value: "custom_date",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        {
          text: "Receipt",
          value: "action",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
          align: "center",
        },
        {
          text: "Type",
          value: "deposit_type",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        // {
        //   text: "Currency",
        //   value: "iron",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        {
          text: "Amount",
          value: "amount",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
        // {
        //   text: "Deposited Currency",
        //   value: "iron",
        //   class: "custom-header black--text",
        //   divider: true,
        //   sortable: false,
        // },
        {
          text: "Deposited Amount",
          value: "deposit_amount",
          class: "custom-header black--text",
          divider: true,
          sortable: false,
        },
      ],
      transactions: [],
    };
  },

  watch: {
    render: function () {
      // If new transaction added then load the transaction list again
      this.getAllTransactions();
    },
  },

  methods: {
    // Get all transaction list
    getAllTransactions() {
      this.loading = true;
      axios
        .get('merchant/show_merchant_own_transactions/'+this.seller_id+'/')
        .then((response) => {
          if (response.data.success && response.data.data) {
            this.transactions = response.data.data;
          }else{
             this.transactions = []
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // View receipt
    onViewReceipt(item) {
      // console.log({ item });
    },
  },

  created() {
    let user = JSON.parse(this.$store.state.user)
    this.seller_id = user.user_id;
    this.getAllTransactions();
  },
};
</script>

<style>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>